import React from 'react'

const Facebook = () => (
  <svg width='32' height='32' fill='none'>
    <path
      fill='#89898C'
      d='M28 6.667v18.666A2.667 2.667 0 0125.333 28h-5a.667.667 0 01-.666-.667v-8h3.12c.317 0 .59-.223.653-.533l.48-2.4a.333.333 0 00-.32-.4h-3.933v-4.333c0-.184.149-.334.333-.334h3.333a.667.667 0 00.667-.666v-2A.667.667 0 0023.333 8H20a4 4 0 00-4 4v4h-1.64a.667.667 0 00-.667.667v2c0 .368.299.666.667.666H16v8a.667.667 0 01-.667.667H6.667A2.667 2.667 0 014 25.333V6.667A2.667 2.667 0 016.667 4h18.666A2.667 2.667 0 0128 6.667z'
    />
  </svg>
)

const Instagram = () => (
  <svg width='32' height='32' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      fill='#89898C'
      d='M21.333 4H10.667A6.667 6.667 0 004 10.667v10.666A6.667 6.667 0 0010.667 28h10.666A6.667 6.667 0 0028 21.333V10.667A6.667 6.667 0 0021.333 4zm4.334 17.333a4.347 4.347 0 01-4.334 4.334H10.667a4.347 4.347 0 01-4.334-4.334V10.667a4.347 4.347 0 014.334-4.334h10.666a4.347 4.347 0 014.334 4.334v10.666zM22.333 11a1.333 1.333 0 100-2.667 1.333 1.333 0 000 2.667zM16 10a6 6 0 106 6 5.986 5.986 0 00-6-6zm-3.667 6a3.667 3.667 0 107.334 0 3.667 3.667 0 00-7.334 0z'
    />
  </svg>
)

const Milkshake = () => (
  <svg width='32' height='32' viewBox='0 0 32 32'>
    <path
      fill='#89898c'
      d='M25.9 4H6.1C5 4 4 5 4 6.1v19.7C4 27 5 28 6.1 28h19.7c1.2 0 2.1-1 2.1-2.1V6.1C28 5 27 4 25.9 4z'
    />
    <path
      fill='#fff'
      d='M18.7 20.4v-.1c-.9 0-.9-.5-.9-1.8v-5.1c.5-.4 1.1-.7 1.5-.7 1 0 1 1 1 1.6v4.2c0 1.4 0 1.8-.9 1.8v.1H25v-.1c-1.3 0-1.5-.3-1.5-2.2v-4.3c0-1.7-.5-2.5-1.9-2.5-1.1 0-2.2.6-3.7 1.8-.1-1.3-.7-1.8-1.8-1.8s-2.1.5-3.7 1.8l.1-1.8-4.9 1.3v.1c1.4-.3 1.7.1 1.7 1.3v4.1c0 1.8-.2 2.2-1.5 2.2v.1h5.6v-.1c-.9 0-.9-.7-.9-1.8v-5.1c.6-.4 1.1-.7 1.4-.7 1 0 1 1 1 1.6v4.2c0 1.4 0 1.8-.9 1.8v.1h4.7z'
    />
  </svg>
)

const Pinterest = () => (
  <svg width='32' height='32' fill='none'>
    <g clipPath='url(#pinterestIconClipPath)'>
      <path
        fill='#89898C'
        d='M3 16c0 5.323 3.201 9.897 7.782 11.907-.037-.907-.007-1.997.226-2.985l1.673-7.084s-.416-.83-.416-2.057c0-1.926 1.117-3.365 2.508-3.365 1.182 0 1.753.888 1.753 1.952 0 1.188-.758 2.966-1.148 4.613-.325 1.38.692 2.504 2.052 2.504 2.463 0 4.122-3.163 4.122-6.912 0-2.849-1.919-4.981-5.41-4.981-3.943 0-6.4 2.94-6.4 6.225 0 1.133.334 1.932.857 2.55.241.284.274.398.187.725-.062.239-.205.815-.264 1.043-.087.33-.354.447-.651.325-1.817-.741-2.663-2.73-2.663-4.966 0-3.693 3.115-8.121 9.292-8.121 4.963 0 8.23 3.591 8.23 7.447 0 5.1-2.836 8.91-7.015 8.91-1.403 0-2.724-.76-3.176-1.621 0 0-.755 2.995-.914 3.574-.276 1.002-.816 2.004-1.309 2.785A12.99 12.99 0 0016 29c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16z'
      />
    </g>
    <defs>
      <clipPath id='pinterestIconClipPath'>
        <path fill='#fff' d='M3 3h26v26H3z' />
      </clipPath>
    </defs>
  </svg>
)

const Twitter = () => (
  <svg width='32' height='32' fill='none'>
    <path
      fill='#89898C'
      d='M29.293 8.619a10.654 10.654 0 01-2.463 2.37v.733a16.253 16.253 0 01-4.827 11.589 16.238 16.238 0 01-11.641 4.688c-2.516.008-5-.566-7.256-1.679a.333.333 0 01-.2-.306v-.147c0-.19.155-.346.346-.346a11.792 11.792 0 006.817-2.45 5.818 5.818 0 01-5.18-3.41.346.346 0 01.347-.48c.68.068 1.367.005 2.024-.187a5.806 5.806 0 01-4.593-5.074.346.346 0 01.479-.347 5.323 5.323 0 002.103.453 5.702 5.702 0 01-2.276-6.58.719.719 0 011.211-.24 16.452 16.452 0 0011.197 5.169 5.334 5.334 0 01-.16-1.332 5.803 5.803 0 019.998-3.916 11.564 11.564 0 003.182-1.106.226.226 0 01.24 0 .226.226 0 010 .24 5.82 5.82 0 01-2.343 2.664c.91-.106 1.804-.32 2.662-.64a.213.213 0 01.24 0 .226.226 0 01.093.334z'
    />
  </svg>
)

const YouTube = () => (
  <svg width='33' height='32' viewBox='0 0 33 32' fill='none'>
    <path
      d='M30.4145 9.12258C30.0932 7.89355 29.1434 6.92581 27.9391 6.59677C25.7564 6 17 6 17 6C17 6 8.24364 6 6.06091 6.59677C4.85659 6.92581 3.90682 7.89355 3.58545 9.12258C3 11.3516 3 16 3 16C3 16 3 20.6484 3.58545 22.8774C3.90682 24.1065 4.85659 25.0742 6.06091 25.4032C8.24364 26 17 26 17 26C17 26 25.7564 26 27.9391 25.4032C29.1434 25.0742 30.0932 24.1065 30.4145 22.8774C31 20.6484 31 16 31 16C31 16 31 11.3516 30.4145 9.12258Z'
      fill='#89898C'
    />
    <path d='M14.1364 20.2212L21.4545 16.0003L14.1364 11.7793V20.2212Z' fill='white' />
  </svg>
)

const LinkedIn = () => (
  <svg width='25' height='25' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fill-rule='evenodd'
      clip-rule='evenodd'
      d='M2 0H16C17.1046 0 18 0.89543 18 2V16C18 17.1046 17.1046 18 16 18H2C0.89543 18 0 17.1046 0 16V2C0 0.89543 0.89543 0 2 0ZM5 15C5.27614 15 5.5 14.7761 5.5 14.5V7.5C5.5 7.22386 5.27614 7 5 7H3.5C3.22386 7 3 7.22386 3 7.5V14.5C3 14.7761 3.22386 15 3.5 15H5ZM4.25 6C3.42157 6 2.75 5.32843 2.75 4.5C2.75 3.67157 3.42157 3 4.25 3C5.07843 3 5.75 3.67157 5.75 4.5C5.75 5.32843 5.07843 6 4.25 6ZM14.5 15C14.7761 15 15 14.7761 15 14.5V9.9C15.0325 8.31078 13.8576 6.95452 12.28 6.76C11.177 6.65925 10.1083 7.17441 9.5 8.1V7.5C9.5 7.22386 9.27614 7 9 7H7.5C7.22386 7 7 7.22386 7 7.5V14.5C7 14.7761 7.22386 15 7.5 15H9C9.27614 15 9.5 14.7761 9.5 14.5V10.75C9.5 9.92157 10.1716 9.25 11 9.25C11.8284 9.25 12.5 9.92157 12.5 10.75V14.5C12.5 14.7761 12.7239 15 13 15H14.5Z'
      fill='#89898C'
    />
  </svg>
)

const TikTok = () => (
  <svg width='20' height='23' viewBox='0 0 17 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M17 5.48001V7.48001C16.9986 7.61984 16.9409 7.7532 16.84 7.85001C16.7389 7.94092 16.6057 7.98772 16.47 7.98001C14.8358 7.87665 13.274 7.26869 12 6.24001V14C12 17.3137 9.31371 20 6 20C2.68629 20 0 17.3137 0 14C0 10.6863 2.68629 8.00001 6 8.00001C6.1832 7.99006 6.3668 7.99006 6.55 8.00001C6.80489 8.02088 7.00085 8.23427 7 8.49001V10.49C7.00104 10.6325 6.93876 10.768 6.83 10.86C6.72124 10.9577 6.57456 11.0017 6.43 10.98C6.28638 10.9767 6.1427 10.9834 6 11C4.34315 11 3 12.3432 3 14C3 15.6569 4.34315 17 6 17C7.65685 17 9 15.6569 9 14V0.500006C9 0.223864 9.22386 6.38427e-06 9.5 6.38427e-06H11.5C11.7577 -0.00128524 11.9742 0.193556 12 0.450006C12.2096 2.87077 14.1292 4.79038 16.55 5.00001C16.799 5.02487 16.9912 5.22995 17 5.48001Z'
      fill='#89898C'
    />
  </svg>
)

export default {
  Facebook,
  Instagram,
  Milkshake,
  Pinterest,
  Twitter,
  YouTube,
  LinkedIn,
  TikTok
}
