import { useEffect, useRef } from 'react'

export default (ref, onClickAway) => {
  const savedCallback = useRef(onClickAway)

  useEffect(() => (savedCallback.current = onClickAway), [onClickAway])

  useEffect(() => {
    const handler = (event) => {
      const { current: el } = ref

      el && !el.contains(event.target) && savedCallback.current(event)
    }

    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)

    return () => {
      document.removeEventListener('mousedown', handler)
      document.removeEventListener('touchstart', handler)
    }
  }, [ref])
}
