import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { useAnchorClickTracking } from '@envato/google-analytics'
import testId from '../../test_helpers/test_id'
import { useParseHref } from '../../routing/parse_href'

/* eslint-disable react/forbid-elements */
// because the <a> in this component lets us forbid it everywhere else

// useParseHref performs further validation on the content of the provided string/object.
export const hrefPropTypes = PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.string)])

Hyperlink.propTypes = {
  href: hrefPropTypes.isRequired,
  children: PropTypes.node.isRequired,
  trackingId: PropTypes.string,
  trackingLabel: PropTypes.string,
  onClick: PropTypes.func
  // plus any other props you can pass to a native <a>
}

export default function Hyperlink({ href, onClick, trackingId, trackingLabel, children, ...props }) {
  const { path, type } = useParseHref(href)

  const trackAnchorClick = useAnchorClickTracking(trackingId, trackingLabel)
  const onClickWithTracking = (event) => {
    if (trackingId) trackAnchorClick()
    if (typeof onClick === 'function') onClick(event)
  }

  switch (type) {
    case 'sameApp':
      return (
        <RouterLink to={path} onClick={onClickWithTracking} {...props} {...testId('RouterLink')}>
          {children}
        </RouterLink>
      )
    case 'sameDomain':
    case 'otherTrustedHost':
    case 'mailto':
      return (
        <a href={path} onClick={onClickWithTracking} {...props}>
          {children}
        </a>
      )
    case 'otherUntrustedHost':
      return (
        <a href={path} onClick={onClickWithTracking} {...props} rel='noopener noreferrer'>
          {children}
        </a>
      )
    default:
      throw new Error(`Unexpected href type: ${type}`)
  }
}
