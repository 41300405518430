import React from 'react'
import PropTypes from 'prop-types'

const Arrow = ({ className }) => (
  <svg className={className} viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
    <path d='M8.00013 2.38382L13.6163 8L8.00013 13.6162L6.77741 12.3935L10.2979 8.86666H2.4668V7.13333H10.2979L6.77741 3.60654L8.00013 2.38382Z' />
  </svg>
)

Arrow.propTypes = {
  className: PropTypes.string
}

export default Arrow
