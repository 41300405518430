import React from 'react'
import Icons from './icons'
import styles from './social_list.module.css'
import Link from '../../link/link'

export default function SocialList() {
  return (
    <ul className={styles.list}>
      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://www.tiktok.com/@envato'
          target='_blank'
          aria-label='TikTok'
          rel='noopener noreferrer'
        >
          <Icons.TikTok />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://www.linkedin.com/company/envato/'
          target='_blank'
          aria-label='LinkedIn'
          rel='noopener noreferrer'
        >
          <Icons.LinkedIn />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://msha.ke/envato/?utm_source=envatocom&utm_medium=web&utm_campaign=social-footer'
          target='_blank'
          aria-label='Milkshake'
          rel='noopener noreferrer'
        >
          <Icons.Milkshake />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://twitter.com/envato'
          target='_blank'
          aria-label='Envato on Twitter'
          rel='noopener noreferrer'
        >
          <Icons.Twitter />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://www.instagram.com/envato/'
          target='_blank'
          aria-label='Envato on Instagram'
          rel='noopener noreferrer'
        >
          <Icons.Instagram />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://www.facebook.com/envato'
          target='_blank'
          aria-label='Envato on Facebook'
          rel='noopener noreferrer'
        >
          <Icons.Facebook />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://www.youtube.com/c/envato'
          target='_blank'
          aria-label='Envato on YouTube'
          rel='noopener noreferrer'
        >
          <Icons.YouTube />
        </Link>
      </li>

      <li className={styles.listItem}>
        <Link
          className={styles.link}
          href='https://pinterest.com/envato/'
          target='_blank'
          aria-label='Envato on Pinterest'
          rel='noopener noreferrer'
        >
          <Icons.Pinterest />
        </Link>
      </li>
    </ul>
  )
}
