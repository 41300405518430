import React from 'react'

import css from './product_card.module.css'

export default function ProductCard({ logo, text }) {
  return (
    <div className={css.root}>
      <span className={css.logo}>{logo}</span>
      <span className={css.text}>{text}</span>
    </div>
  )
}
