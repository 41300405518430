import React from 'react'
import Arrow from './arrow'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './anchor_with_arrow.module.css'
import Link from '../link/link'

const AnchorWithArrow = ({ className, style, arrowDirection = 'right', children, ...attrs }) => (
  <Link className={classnames(styles.root, className)} style={style} {...attrs}>
    <span>{children}</span>
    <Arrow
      className={classnames(styles.arrow, {
        [styles.arrowDown]: arrowDirection === 'down',
        [styles.arrowRight]: arrowDirection === 'right'
      })}
    />
  </Link>
)
AnchorWithArrow.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  arrowDirection: PropTypes.oneOf(['right', 'down']),
  children: PropTypes.node.isRequired
}

export default AnchorWithArrow
