import { useLanguage } from '../i18n'
import { languages, defaultLanguage } from '../i18n/languages'
import { linkedDomains as trustedDomains } from '@envato/google-analytics'

/*
A href in Frontdoor can be:

- An object, defining language-specific paths for a page (see frontdoorPaths & frontdoorAnchors)
  - e.g. { en: "/english-page", es: "/es/spanish-page" }
- A root-relative path, e.g. "/blah/" (see otherEnvatoComPaths)
- An absolute URL, e.g. "https://elements.envato.com/volcanoes/"
- A mailto link, e.g. "mailto:test@example.org"

This hook parses a href of any of these types and returns a consistent object:
- href: string
- type: 'sameApp' | 'sameDomain' | 'otherTrustedHost' | 'otherUntrustedHost' | 'mailto'
*/

export const useParseHref = (href) => {
  const language = useLanguage()
  return parseHref(href, language)
}

export const parseHref = (href, language) => {
  if (typeof href === 'object') {
    if (!languages[language]) throw new Error(`Unknown language: ${language}`)

    const path = href[language] || href[defaultLanguage]

    if (!path)
      throw new Error(`No path defined for language "${language}" or for default language "${defaultLanguage}"`)

    if (path.includes('#')) {
      return {
        path: path,
        type: 'sameDomain'
      }
    } else {
      return {
        path: path,
        type: 'sameApp'
      }
    }
  } else if (typeof href === 'string' && !!href) {
    if (href.startsWith('#')) throw new Error(`Invalid href "${href}". Hash links must include a pathname.`)

    if (href.startsWith('/')) {
      return {
        path: rewriteForReactSnap(href),
        type: 'sameDomain'
      }
    } else {
      const { host, protocol } = new URL(href)

      if (protocol === 'mailto:') {
        return {
          path: href,
          type: 'mailto'
        }
      } else if (protocol === 'http:' || protocol === 'https:') {
        const isTrustedHost = trustedDomains.some((trustedDomain) => host.endsWith(trustedDomain))

        return {
          path: href,
          type: isTrustedHost ? 'otherTrustedHost' : 'otherUntrustedHost'
        }
      } else {
        throw new Error(`Unrecognised href protocol "${protocol}" in href "${href}"`)
      }
    }
  } else {
    throw new Error(`Unrecognised href format: ${href}`)
  }
}

// react-snap assumes all hrefs beginning with `/` are pages in the current CRA app and crawls them
// Links to non-frontdoor paths on www.envato.com, e.g. /blog/
// will result in react-snap generating an empty page for that URL.
function rewriteForReactSnap(pathname) {
  return process.env.NODE_ENV === 'production' ? `https://www.envato.com${pathname}` : pathname
}
