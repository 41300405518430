import { useContext, useEffect, useState } from 'react'
import IntersectionHandlerContext from '../../contexts/intersection_handler_context'

export default function useIsElementOnScreen(cssId) {
  const [isDisplayed, setIsDisplayed] = useState(null)
  const intersectionHandler = useContext(IntersectionHandlerContext)

  useEffect(() => {
    intersectionHandler.addHandler((entries) => {
      entries.forEach((entry) => {
        if (entry.target.id === cssId) {
          setIsDisplayed(entry.isIntersecting)
        }
      })
    })
    // The handler should only be added once, hence empty deps []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isDisplayed
}
