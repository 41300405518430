import React from 'react'
import styles from './loading.module.css'

export default function Loading({ showSpinner = false, takingTooLong = false }) {
  return (
    <div className={styles.root}>
      {showSpinner || takingTooLong ? (
        <svg viewBox='0 0 64 64' fill='#3e3f3d' className={styles.spinner}>
          <path d='M34.286 14.446a2.286 2.286 0 0 1-4.572 0V2.743a2.286 2.286 0 0 1 4.572 0v11.703z' />
          <path
            d='M42.757 17.94a2.286 2.286 0 1 1-3.96-2.285L44.65 5.52a2.286 2.286 0 1 1 3.96 2.286L42.756 17.94z'
            fill='none'
          />
          <path
            d='M48.345 25.202a2.286 2.286 0 0 1-2.286-3.959l10.136-5.851a2.286 2.286 0 0 1 2.286 3.959l-10.136 5.851z'
            fillOpacity='.1'
          />
          <path
            d='M49.554 34.286a2.286 2.286 0 0 1 0-4.572h11.703a2.286 2.286 0 0 1 0 4.572H49.554z'
            fillOpacity='.15'
          />
          <path
            d='M46.06 42.757a2.286 2.286 0 1 1 2.285-3.96L58.48 44.65a2.286 2.286 0 1 1-2.286 3.96L46.06 42.756z'
            fillOpacity='.25'
          />
          <path
            d='M38.798 48.345a2.286 2.286 0 0 1 3.959-2.286l5.851 10.136a2.286 2.286 0 0 1-3.959 2.286l-5.851-10.136z'
            fillOpacity='.35'
          />
          <path
            d='M29.714 49.554a2.286 2.286 0 0 1 4.572 0v11.703a2.286 2.286 0 0 1-4.572 0V49.554z'
            fillOpacity='.45'
          />
          <path
            d='M21.243 46.06a2.286 2.286 0 1 1 3.96 2.285L19.35 58.48a2.286 2.286 0 1 1-3.96-2.286l5.852-10.136z'
            fillOpacity='.55'
          />
          <path
            d='M15.655 38.798a2.286 2.286 0 0 1 2.286 3.959L7.805 48.608a2.286 2.286 0 0 1-2.286-3.959l10.136-5.851z'
            fillOpacity='.65'
          />
          <path d='M14.446 29.714a2.286 2.286 0 0 1 0 4.572H2.743a2.286 2.286 0 0 1 0-4.572h11.703z' fillOpacity='.7' />
          <path
            d='M17.94 21.243a2.286 2.286 0 1 1-2.285 3.96L5.52 19.35a2.286 2.286 0 1 1 2.286-3.96l10.136 5.852z'
            fillOpacity='.85'
          />
          <path d='M25.202 15.655a2.286 2.286 0 0 1-3.959 2.286L15.392 7.805a2.286 2.286 0 0 1 3.959-2.286l5.851 10.136z' />
        </svg>
      ) : null}

      {takingTooLong ? (
        <p className={styles.message}>
          Hmm… this is taking longer than expected. You may want to check your connection or try reloading the page.
        </p>
      ) : null}
    </div>
  )
}
