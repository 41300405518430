import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './page_layout_with_header.module.css'
import Navigation from '../navigation/navigation'
import { Footer, cookieSettingsEffect } from '@envato/ui'
import { Analytics } from '@envato/google-analytics'
import { frontdoorAnchors, frontdoorPaths, otherEnvatoComPaths } from '../../routing/paths'
import IntersectionHandlerContext, { createIntersectionHandler } from '../../contexts/intersection_handler_context'

PageLayoutWithHeader.propTypes = {
  children: PropTypes.node.isRequired
}

export default function PageLayoutWithHeader({ children }) {
  const links = {
    vertical: [
      { href: frontdoorPaths.products, text: 'Products' },
      { href: frontdoorPaths.aboutPurpose, text: 'Purpose' },
      { href: frontdoorPaths.affiliatesPrograms, text: 'Affiliates' },
      { href: frontdoorPaths.cookies, text: 'Cookies' },
      { href: frontdoorPaths.aboutCareers, text: 'Careers' },
      { href: otherEnvatoComPaths.blog, text: 'Blog' },
      { href: 'https://forums.envato.com/', text: 'Forum' },
      { href: otherEnvatoComPaths.news, text: 'News' },
      { href: frontdoorPaths.aboutCompany, text: 'About Us' },
      { href: 'https://author.envato.com/', text: 'Sellers' },
      { href: frontdoorPaths.contact, text: 'Contact Us' },
      { href: frontdoorPaths.privacy, text: 'Privacy' },
      { href: '/#cookieSettings', text: 'Cookie Settings', id: 'cookieSettings' },
      { href: frontdoorAnchors.myPersonalInformation, text: 'Do not sell or share my personal information' }
    ],
    horizontal: [
      { href: 'https://elements.envato.com/', text: 'Envato Elements' },
      { href: 'https://market.envato.com/', text: 'Envato Market' },
      { href: 'https://tutsplus.com', text: 'Envato Tuts+' },
      { href: 'https://placeit.net/', text: 'Placeit by Envato' },
      { href: frontdoorPaths.sitemap, text: 'Sitemap' }
    ]
  }

  useEffect(cookieSettingsEffect, [])

  return (
    <div className={styles.root}>
      <IntersectionHandlerContext.Provider value={createIntersectionHandler()}>
        <Analytics.Section trackingId='page_header'>
          <div className={styles.header}>
            <Navigation />
          </div>
        </Analytics.Section>
        <div className={styles.contentWithTopPadding}>{children}</div>
        <Footer links={links} />{' '}
      </IntersectionHandlerContext.Provider>
    </div>
  )
}
