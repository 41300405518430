import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import { Analytics } from '@envato/google-analytics'
import { Dropdown, Logo, useClickAway } from '@envato/ui'
import { frontdoorPaths } from '../../../routing/paths'
import Hyperlink, { hrefPropTypes } from '../../hyperlink/hyperlink'

import css from './top_nav.module.css'
import hamburger from './hamburger.svg'
import close from './close.svg'

const TopNav = ({ logoHref, links, altStyle = false }) => {
  const dropdownRef = useRef(null)
  const hamburgerRef = useRef(null)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [menuVisible, setMenuVisible] = useState(false)

  useClickAway(dropdownRef, () => dropdownVisible && setDropdownVisible(false))
  useClickAway(hamburgerRef, () => menuVisible && setMenuVisible(false))

  return (
    <div className={altStyle ? css.altRoot : css.root}>
      <nav className={css.nav}>
        <div ref={hamburgerRef} className={css.colContainer}>
          <Analytics.Button
            className={css.hamburger}
            onClick={() => setMenuVisible(!menuVisible)}
            id='nav-hamburger'
            aria-label='Hamburger menu'
            aria-haspopup='true'
            aria-expanded={menuVisible}
            trackingId='hamburger'
            trackingLabel={menuVisible ? 'close' : 'open'}
          >
            {menuVisible ? (
              <img loading='lazy' src={close} height='16' width='16' alt='Close menu' />
            ) : (
              <img loading='lazy' src={hamburger} height='16' width='16' aria-hidden='true' alt='Hamburger' />
            )}
          </Analytics.Button>
          <Hyperlink className={css.logo} href={logoHref} title='Envato'>
            <Logo whichLogo='envato' />
          </Hyperlink>
          <CSSTransition in={menuVisible} timeout={300}>
            <ul className={css.links} aria-labelledby='nav-hamburger'>
              {links.map(({ href, anchorText, trackingLabel }, i) => (
                <li className={css.linkItem} key={i}>
                  <Hyperlink href={href} trackingId='nav_link' trackingLabel={trackingLabel}>
                    {anchorText}
                  </Hyperlink>
                </li>
              ))}
            </ul>
          </CSSTransition>
        </div>
        <div className={css.colContainer}>
          <div ref={dropdownRef}>
            <Dropdown link={frontdoorPaths.aboutCompany} show={dropdownVisible}>
              <Analytics.Button
                type='button'
                onClick={() => setDropdownVisible(!dropdownVisible)}
                className={css.products}
                aria-haspopup='true'
                trackingId='our_products_bento'
                trackingLabel={dropdownVisible ? 'close' : 'open'}
              >
                <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M3.333 0h-2C.597 0 0 .597 0 1.333v2c0 .737.597 1.334 1.333 1.334h2c.737 0 1.334-.597 1.334-1.334v-2C4.667.597 4.07 0 3.333 0zm0 3.333h-2v-2h2v2zM10.667 0h-2C7.93 0 7.333.597 7.333 1.333v2c0 .737.597 1.334 1.334 1.334h2c.736 0 1.333-.597 1.333-1.334v-2C12 .597 11.403 0 10.667 0zm0 3.333h-2v-2h2v2zm-6 5.334c0-.737-.597-1.334-1.334-1.334h-2C.597 7.333 0 7.93 0 8.667v2C0 11.403.597 12 1.333 12h2c.737 0 1.334-.597 1.334-1.333v-2zm-1.334 2h-2v-2h2v2zm7.334-3.334h-2c-.737 0-1.334.597-1.334 1.334v2c0 .736.597 1.333 1.334 1.333h2c.736 0 1.333-.597 1.333-1.333v-2c0-.737-.597-1.334-1.333-1.334zm0 3.334h-2v-2h2v2z'
                    fill='#717275'
                  />
                </svg>
                <span className={css.productButtonText}>Our Products</span>
                <svg
                  role='img'
                  aria-label='chevron down'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M8 12L3 6L13 6L8 12Z' fill='#717275' />
                </svg>
              </Analytics.Button>
            </Dropdown>
          </div>
        </div>
      </nav>
    </div>
  )
}

TopNav.propTypes = {
  logoHref: PropTypes.object.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: hrefPropTypes.isRequired,
      anchorText: PropTypes.string.isRequired,
      track: PropTypes.func
    })
  )
}

export default TopNav
