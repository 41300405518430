import { useContext } from 'react'
import I18nContext from '../contexts/i18n_context'
import { frontdoorPaths } from '../routing/paths'
import { defaultLanguage } from './languages'

export const createI18nPages = (pages) => {
  return pages.reduce((acc, { pageId, title, description, Component }) => {
    const translations = Object.keys(frontdoorPaths[pageId])
    const alternate = Object.entries(frontdoorPaths[pageId])

    return [
      ...acc,
      ...translations.map((lang) => ({
        pageId,
        lang,
        Component,
        alternate: alternate.filter(([altLang]) => altLang !== lang),
        path: frontdoorPaths[pageId][lang],
        title: title[lang],
        description: description[lang]
      }))
    ]
  }, [])
}

export const useTranslation = (translationMap) => {
  if (!translationMap) throw new Error('You must provide a translation map to this hook.')
  if (!(defaultLanguage in translationMap))
    throw new Error(`The translation map must provide a "${defaultLanguage}" key.`)

  const { language } = useContext(I18nContext)
  const translations = language in translationMap ? translationMap[language] : translationMap[defaultLanguage]

  return translations
}

export const useLanguage = () => {
  const { language } = useContext(I18nContext)
  return language
}
