// Add an test-specific data attribute to a DOM element
// used to identify the element in tests but will not appear in production
// <button {...testId('MySpecialLink')}>
export default function testId(id) {
  if (process.env.NODE_ENV === 'test') {
    return { 'data-test-id': id }
  } else {
    return {}
  }
}
