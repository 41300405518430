import linkedDomains from './linked_domains'

// Tracking ID for www.envato.com
const DEFAULT_GA_TRACKING_ID = 'UA-11834194-1'

// In production and staging only, set up GA as per https://github.com/envato/ga-reference-implementation/
export default function setupGoogleAnalytics({ trackingID = DEFAULT_GA_TRACKING_ID } = {}) {
  if (typeof window === 'undefined') return

  if (process.env.NODE_ENV === 'production' && window.navigator.userAgent !== 'ReactSnap') {
    productionSetup()
  } else {
    developmentSetup()
  }

  window.addEventListener('ga-ready', () => {
    // Create a cookie-based GA tracker
    // allowLinker option means it will read client ID from query params instead of generating a new one
    // when visitor is inbound from another linked domain.
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/creating-trackers
    window.ga('create', trackingID, 'auto', { allowLinker: true })

    // Require the Linker plugin
    // https://developers.google.com/analytics/devguides/collection/analyticsjs/linker
    window.ga('require', 'linker')

    // Add event listeners that append the Client ID to the URL when following a link to one of the above domains.
    window.ga('linker:autoLink', linkedDomains)
  })
}

function productionSetup() {
  window.addEventListener('load', () => {
    // Set up globals (`GoogleAnalyticsObject` and `ga`) and load GA's analytics.js onto the page
    // Snippet from https://developers.google.com/analytics/devguides/collection/analyticsjs/
    /* eslint-disable */
    ;(function (i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r
      ;(i[r] =
        i[r] ||
        function () {
          ;(i[r].q = i[r].q || []).push(arguments)
        }),
        (i[r].l = 1 * new Date())
      a = s.createElement(o)
      a.async = 1
      a.defer = true
      a.type = 'text/plain'
      a.setAttribute('data-cookieconsent', 'marketing')
      a.src = g
      s.body.appendChild(a)

      window.dispatchEvent(new Event('ga-ready'))
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga')

    // Setup GTM
    ;(function (w, d, s, l, i) {
      w[l] = w[l] || []
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
      var j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : ''
      j.async = true
      j.defer = true
      j.type = 'text/plain'
      j.setAttribute('data-cookieconsent', 'marketing')
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
      d.body.appendChild(j)
    })(window, document, 'script', 'dataLayer', 'GTM-N9BRDFF')
    /* eslint-enable */
  })
}

function developmentSetup() {
  window.ga = (...args) => {
    // noop
  }
}
