export default function reportExperimentEnrolment({ id, activeVariant }) {
  window.ga('set', 'expId', id)
  window.ga('set', 'expVar', activeVariant.id)
  window.ga('send', {
    hitType: 'event',
    eventCategory: 'experiment',
    eventAction: 'view',
    eventLabel: id,
    eventValue: activeVariant.id,
    nonInteraction: true
  })
}
