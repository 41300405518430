// Domains across which a consistent Client ID should be used
// so the Analytics team can track user sessions across our multiple domains.
// Use this as the `domains` param with the GA Linker plugin.
// https://developers.google.com/analytics/devguides/collection/analyticsjs/linker#autolink
export default [
  '3docean.net',
  'activeden.net',
  'audiojungle.net',
  'build.envatohostedservices.com',
  'codecanyon.net',
  'envato.com',
  'graphicriver.net',
  'mixkit.co',
  'photodune.net',
  'placeit.net',
  'themeforest.net',
  'tutsplus.com',
  'twenty20.com',
  'videohive.net'
]
