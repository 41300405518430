import React from 'react'
import PropTypes from 'prop-types'
import authorHub from './logos/authorhub.svg'
import bcorp from './logos/bcorp.svg'
import blog from './logos/blog.svg'
import elements from './logos/elements.svg'
import envato from './logos/envato.svg'
import envatoFoundation from './logos/envato_foundation.svg'
import market from './logos/market.svg'
import milkshake from './logos/milkshake.svg'
import mixkit from './logos/mixkit.svg'
import placeit from './logos/placeit.svg'
import reshot from './logos/reshot.svg'
import tutsplus from './logos/tutsplus.svg'

Logo.propTypes = {
  whichLogo: PropTypes.oneOf([
    'authorHub',
    'envato',
    'envato_foundation',
    'market',
    'elements',
    'placeit',
    'tutsplus',
    'milkshake',
    'mixkit',
    'reshot',
    'blog',
    'bcorp'
  ]).isRequired,
  className: PropTypes.string
}

export default function Logo({ whichLogo, className, ...rest }) {
  const logo = {
    authorHub,
    bcorp,
    blog,
    elements,
    envato,
    envato_foundation: envatoFoundation,
    market,
    milkshake,
    mixkit,
    placeit,
    reshot,
    tutsplus
  }[whichLogo]

  return (
    <img loading='lazy' src={logo} alt={`${whichLogo} logo`} className={className} data-logo={whichLogo} {...rest} />
  )
}
