import classnames from 'classnames'
import React from 'react'
import Link from '../link/link'
import Logo from '../logo/logo'
import ProductCard from '../product_card/product_card'
import { CSSTransition } from 'react-transition-group'

import css from './dropdown.module.css'

const products = [
  {
    logo: <Logo whichLogo='elements' />,
    text: 'Subscribe for unlimited digital assets',
    link: 'https://elements.envato.com'
  },
  {
    logo: <Logo whichLogo='market' />,
    text: 'Buy and sell digital assets',
    link: 'https://themeforest.net/'
  },
  {
    logo: <Logo whichLogo='placeit' />,
    text: 'Create your own mockups and assets',
    link: 'https://placeit.net/'
  },
  {
    logo: <Logo whichLogo='tutsplus' />,
    text: 'Upskill with tutorials and courses',
    link: 'https://tutsplus.com/'
  },
  {
    logo: <Logo whichLogo='mixkit' />,
    text: 'Browse free stock video and art',
    link: 'https://mixkit.co/'
  }
]

export default function Dropdown({
  title = 'Envato is the leading marketplace for creative assets and creative people',
  link,
  show,
  children
}) {
  return (
    <div aria-expanded={show.toString()} className={css.container}>
      {children}
      <div className={classnames(css.root, { [css.appear]: show })}>
        <div className={css.scrollContainer}>
          <h4 className={css.title}>{title}</h4>
          <Link className={css.link} href={link}>
            About Envato
            <svg
              className={css.arrow}
              width='13'
              height='12'
              viewBox='0 0 13 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M6.50013 0.384766L12.1163 6.00094L6.50013 11.6171L5.27741 10.3944L8.7979 6.86761H0.966797V5.13428H8.7979L5.27741 1.60748L6.50013 0.384766Z'
                fill='#008C8E'
              />
            </svg>
          </Link>
          {products.map((product) => (
            <Link key={product.link} href={product.link} className={css.product}>
              <ProductCard
                logo={
                  <CSSTransition unmountOnExit in={show} timeout={300}>
                    {product.logo}
                  </CSSTransition>
                }
                text={product.text}
              />
            </Link>
          ))}
        </div>
      </div>
    </div>
  )
}
