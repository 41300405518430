import { useContext, useCallback } from 'react'
import { PageContext, SectionContext } from './contexts'

export const useAnchorClickTracking = (trackingId, trackingLabel) =>
  useClickTracking({
    tagName: 'a',
    elementId: trackingId,
    elementLabel: trackingLabel
  })

export const useButtonClickTracking = (trackingId, trackingLabel) =>
  useClickTracking({
    tagName: 'button',
    elementId: trackingId,
    elementLabel: trackingLabel
  })

// Exporting these because Influx currently tracks clicks on a couple of things that aren't links or buttons.
// We probably shouldn't keep adding these for every conceivable element... revisit approach if you find yourself here.
export const useSelectClickTracking = (trackingId, trackingLabel) =>
  useClickTracking({
    tagName: 'select',
    elementId: trackingId,
    elementLabel: trackingLabel
  })
export const useDivClickTracking = (trackingId, trackingLabel) =>
  useClickTracking({
    tagName: 'div',
    elementId: trackingId,
    elementLabel: trackingLabel
  })

function useClickTracking({ elementId, elementLabel, tagName }) {
  const pageId = useContext(PageContext)
  const sectionId = useContext(SectionContext)

  const handler = useCallback(() => {
    if (!elementId) return
    warnPage(pageId)
    warnSection(sectionId)

    if (pageId && sectionId) {
      sendStandardizedClickEvent({
        pageId,
        sectionId,
        elementId,
        elementLabel,
        tagName
      })
    }
  }, [pageId, sectionId, elementId, elementLabel, tagName])

  return handler
}

function sendStandardizedClickEvent({ pageId, sectionId, elementId, elementLabel, tagName }) {
  window.ga('send', {
    hitType: 'event',
    eventCategory: `${sectionId}`,
    eventAction: `click;${tagName}`,
    eventLabel: `${elementId}${elementLabel ? `;${elementLabel}` : ''}`,
    dimension11: `${pageId}`
  })
}

function warnPage(pageId) {
  if (!pageId) console.warn("An element with a trackingId was clicked, but it wasn't nested in an <Analytics.Page>")
}

function warnSection(sectionId) {
  if (!sectionId)
    console.warn("An element with a trackingId was clicked, but it wasn't nested in an <Analytics.Section>")
}
