import React from 'react'
import PropTypes from 'prop-types'
import styles from './footer_nav.module.css'
import classnames from 'classnames'
import Link from '../../link/link'

export default function FooterNav({ links, vertical }) {
  return (
    <nav role='navigation'>
      <ul
        className={classnames(styles.list, {
          [styles.vertical]: vertical
        })}
      >
        {links[vertical ? 'vertical' : 'horizontal'].map(({ href, text, id }) => (
          <li key={text} className={styles.listItem}>
            <Link className={styles.link} href={href} id={id}>
              {text}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

FooterNav.defaultProps = {
  vertical: false
}

FooterNav.propTypes = {
  vertical: PropTypes.bool
}
