import { languages } from '../i18n/languages'

// Frontdoor paths
// *********************
// Paths that can be navigated within this app via a React Router <Link to={path}>
//
// ⚠️ react-snap works by crawling links from a starting page (in our case, home)
// if a URL can't be reached by crawling from home, you must add it to
// reactSnap.include in package.json to force a crawl.

export const frontdoorPaths = {
  aboutCareers: { [languages.en]: '/about/careers/', [languages.es]: '/es/about/careers/' },
  aboutCareersGuadalajara: {
    [languages.en]: '/about/careers/guadalajara/',
    [languages.es]: '/es/about/careers/guadalajara/'
  },
  aboutCareersMelbourne: {
    [languages.en]: '/about/careers/australia-and-new-zealand/',
    [languages.es]: '/es/about/careers/australia-and-new-zealand/'
  },
  aboutCompany: { [languages.en]: '/about/' },
  aboutPurpose: { [languages.en]: '/about/purpose/' },
  aboutSustainability: { [languages.en]: '/about/sustainability/' },
  affiliatesFAQs: { [languages.en]: '/affiliates/faqs/' },
  affiliatesHowToCreateLink: { [languages.en]: '/affiliates/how-do-i-create-a-referral-link-in-impact/' },
  affiliatesHowToGetPaid: { [languages.en]: '/affiliates/how-do-i-get-paid/' },
  affiliatesHowToImpact: { [languages.en]: '/affiliates/how-do-i-find-ads-in-impact/' },
  affiliatesPayout: { [languages.en]: '/affiliates/envato-affiliates-payout-process/' },
  affiliatesPrograms: { [languages.en]: '/affiliates/' },
  antiBribery: { [languages.en]: '/anti-bribery-and-corruption/' },
  archivedPrivacyPolicy2019_04_10: { [languages.en]: '/privacy/archive/2019-04-10/' },
  archivedPrivacyPolicy2021_10_11: { [languages.en]: '/privacy/archive/2021-10-11/' },
  archivedPrivacyPolicy2022_03_15: { [languages.en]: '/privacy/archive/2022-03-15/' },
  archivedPrivacyPolicy2022_08_04: { [languages.en]: '/privacy/archive/2022-08-04/' },
  archivedPrivacyPolicy2022_09_06: { [languages.en]: '/privacy/archive/2022-09-06/' },
  archivedPrivacyPolicy2024_04_08: { [languages.en]: '/privacy/archive/2024-04-08/' },
  archivedWhistleblowerPolicy2019_12_24: { [languages.en]: '/whistleblower-policy/archive/2019-12-24/' },
  contact: { [languages.en]: '/contact/' },
  cookies: { [languages.en]: '/cookies/' },
  dsaPolicy: { [languages.en]: '/digital-services-act-eu/' },
  home: { [languages.en]: '/' },
  privacy: { [languages.en]: '/privacy/' },
  products: { [languages.en]: '/products/' },
  sitemap: { [languages.en]: '/sitemap/' },
  slaveryPolicy: { [languages.en]: '/slavery-policy/' },
  whistleblowerPolicy: { [languages.en]: '/whistleblower-policy/' }
}

// Frontdoor anchors
// *********************
// URLs that rely on a specific element id existing on a page
// Paths added here should have a test that ensures we don't delete
// or change the necessary element/id (see current tests for an example)
export const frontdoorAnchors = {
  affiliatesPrograms: { [languages.en]: '/affiliates/#programs' },
  careersPositions: {
    [languages.en]: '/about/careers/#positions',
    [languages.es]: '/es/about/careers/#positions'
  },
  guadalajaraCareersPositions: {
    [languages.en]: '/about/careers/guadalajara/#positions',
    [languages.es]: '/es/about/careers/guadalajara/#positions'
  },
  legacyCareersPositions: { [languages.en]: '/careers/#positions' },
  melbourneCareersPositions: {
    [languages.en]: '/about/careers/australia-and-new-zealand/#positions',
    [languages.es]: '/es/about/careers/australia-and-new-zealand/#positions'
  },
  myPersonalInformation: { [languages.en]: '/privacy/#my-personal-information' }
}

// Other www.envato.com paths
// *********************
// Paths in other apps that we share the https://www.envato.com host with
// Specifying them as relative links here (rather than absolutely with a host)
// means you won't accidentally navigate from dev to prod.
export const otherEnvatoComPaths = {
  blog: '/blog/',
  news: '/blog/news/'
}
