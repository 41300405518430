import React, { useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { HeadProvider } from 'react-head'
import PageRoutes from './routing/page_routes'
import ExperimentContext from './contexts/experiment_context'
import { experimentId, variants } from './experiment/config'
import getVariant from './experiment/get_variant'
import { reportExperimentEnrolment } from '@envato/google-analytics'

export default function App() {
  const [experiment, setExperiment] = useState({
    id: experimentId,
    variants,
    activeVariant: null
  })

  useEffect(() => {
    if (experimentId) {
      const activeVariant = getVariant()
      const experiment = {
        id: experimentId,
        variants,
        activeVariant
      }

      if (activeVariant) {
        reportExperimentEnrolment(experiment)
      }

      setExperiment(experiment)
    }
  }, [])

  return (
    <BrowserRouter>
      <ExperimentContext.Provider value={experiment}>
        <HeadProvider headTags={[]}>
          <PageRoutes />
        </HeadProvider>
      </ExperimentContext.Provider>
    </BrowserRouter>
  )
}
