import { createContext } from 'react'

const IntersectionHandlerContext = createContext(null)

class IntersectionHandler {
  constructor(options = {}) {
    this.observer = new IntersectionObserver((entries, observer) => this.runHandlers(entries, observer), options)
    this.handlers = []
  }

  runHandlers(entries, _observer) {
    this.handlers.forEach((func) => {
      func(entries)
    })
  }

  addHandler(func) {
    this.handlers.push(func)
  }

  observe(target) {
    this.observer.observe(target)
  }

  unobserve(target) {
    this.observer.unobserve(target)
  }
}

export default IntersectionHandlerContext

export const SUSTAINABILITY_HEADER_CSS_ID = 'sustainabilityHeader'

export function createIntersectionHandler() {
  const options = { rootMargin: '-56px' } // Account for nav height
  return new IntersectionHandler(options)
}
