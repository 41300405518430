import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CSSTransition } from 'react-transition-group'
import { useLocation, matchPath } from 'react-router-dom'
import chevron from './chevron.svg'
import css from './sub_nav.module.css'
import Hyperlink, { hrefPropTypes } from '../../hyperlink/hyperlink'
import { useClickAway } from '@envato/ui'
import { Analytics } from '@envato/google-analytics'
import { parseHref } from '../../../routing/parse_href'
import { useLanguage } from '../../../i18n'
import LanguagePicker from '../../language_picker/language_picker'

const SubNav = ({ links, showLanguagePicker, section, altStyle = false }) => {
  const ref = useRef(null)
  const [menuVisible, setMenuVisible] = useState(false)
  useClickAway(ref, () => menuVisible && setMenuVisible(false))

  const language = useLanguage()
  const location = useLocation()

  const activePage = links.find(({ href, childHrefs }) => {
    const { path, type } = parseHref(href, language)
    if (type !== 'sameApp') return false
    if (matchPath(location.pathname, { path, exact: true })) return true
    if (childHrefs) {
      return childHrefs.some((childHref) =>
        matchPath(location.pathname, {
          path: parseHref(childHref, language).path,
          exact: true
        })
      )
    }
    return false
  })

  return (
    <div ref={ref} className={altStyle ? css.altRoot : css.root}>
      <span className={css.title}>{section}</span>
      <Analytics.Button
        className={css.smallScreenActivePage}
        onClick={() => setMenuVisible(!menuVisible)}
        trackingId='subnav_small_screen_dropdown'
        trackingLabel={menuVisible ? 'close' : 'open'}
      >
        {activePage.anchorText}
        <img
          loading='lazy'
          className={classnames(css.chevron, { [css.open]: menuVisible })}
          src={chevron}
          aria-hidden='true'
          alt='Chevron'
        />
      </Analytics.Button>
      <CSSTransition in={menuVisible} timeout={300}>
        <ul className={css.links}>
          {links.map(({ href, anchorText, trackingLabel }) => {
            const pageIsActive = activePage.href === href

            return (
              <li className={css.linkItem} key={anchorText}>
                <Hyperlink
                  className={classnames(css.link, {
                    [css.active]: pageIsActive
                  })}
                  href={href}
                  trackingId='subnav_link'
                  trackingLabel={trackingLabel}
                >
                  {anchorText}
                </Hyperlink>
              </li>
            )
          })}
        </ul>
      </CSSTransition>
      {showLanguagePicker ? (
        <div className={css.languagePicker}>
          <LanguagePicker />
        </div>
      ) : null}
    </div>
  )
}

SubNav.propTypes = {
  section: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: hrefPropTypes.isRequired,
      anchorText: PropTypes.string.isRequired,
      trackingLabel: PropTypes.string.isRequired
    })
  ),
  showLanguagePicker: PropTypes.bool
}

export default SubNav
