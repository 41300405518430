import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Switch, Route } from 'react-router'
import { frontdoorPaths } from '../../routing/paths'
import TopNav from './top_nav/top_nav'
import SubNav from './sub_nav/sub_nav'
import css from './navigation.module.css'
import useScrollDirection from './use_scroll_direction'
import { useLanguage } from '../../i18n'
import { parseHref } from '../../routing/parse_href'
import useAltNavStyle from './use_alt_nav_style'

const links = [
  {
    href: frontdoorPaths.products,
    anchorText: 'Products',
    trackingLabel: 'products'
  },
  {
    href: frontdoorPaths.aboutCompany,
    anchorText: 'About',
    trackingLabel: 'about'
  },
  { href: 'https://forums.envato.com', anchorText: 'Forums', trackingLabel: 'forums' },
  { href: 'https://www.envato.com/blog/', anchorText: 'Blog', trackingLabel: 'blog' }
]

const companySubLinks = [
  {
    href: frontdoorPaths.aboutCompany,
    anchorText: 'Company',
    trackingLabel: 'company'
  },
  {
    href: frontdoorPaths.aboutPurpose,
    anchorText: 'Purpose',
    trackingLabel: 'purpose'
  }
]

const careersSubLinks = [
  {
    href: frontdoorPaths.aboutCareers,
    anchorText: 'Careers',
    trackingLabel: 'careers',
    childHrefs: [frontdoorPaths.aboutCareersGuadalajara, frontdoorPaths.aboutCareersMelbourne]
  }
]

const sustainabilitySublinks = [
  {
    href: frontdoorPaths.aboutSustainability,
    anchorText: 'Sustainability',
    trackingLabel: 'sustainability'
  }
]

const affiliatesSubLinks = {
  section: 'Affiliates',
  links: [
    {
      href: frontdoorPaths.affiliatesPrograms,
      anchorText: 'Programs',
      trackingLabel: 'affiliates'
    },
    {
      href: frontdoorPaths.affiliatesFAQs,
      anchorText: 'FAQs',
      trackingLabel: 'faqs'
    }
  ]
}

const Navigation = ({ minScroll = 400 }) => {
  const direction = useScrollDirection({ min: minScroll })
  const language = useLanguage()
  const companyPaths = companySubLinks.map(({ href }) => parseHref(href, language).path)
  const sustainabilityPaths = sustainabilitySublinks.map(({ href }) => parseHref(href, language).path)
  const affiliatesPaths = affiliatesSubLinks.links.map(({ href }) => parseHref(href, language).path)
  const careersPaths = careersSubLinks.map(({ href }) => parseHref(href, language).path)
  const aboutSubLinks = {
    section: 'About us',
    links: [...companySubLinks, ...careersSubLinks, ...sustainabilitySublinks]
  }
  const showAltNavStyle = useAltNavStyle()

  const navWrapper = (includedSubLinks, showLanguagePicker = false) => (
    <div className={classnames(css.root, { [css.margin]: includedSubLinks })}>
      <div
        className={classnames(css.nav, {
          [css.showAll]: !includedSubLinks || !direction || direction === 'up'
        })}
      >
        <TopNav logoHref={frontdoorPaths.aboutCompany} links={links} altStyle={showAltNavStyle} />
        {includedSubLinks ? (
          <SubNav
            links={includedSubLinks.links}
            section={includedSubLinks.section}
            showLanguagePicker={showLanguagePicker}
            altStyle={showAltNavStyle}
          />
        ) : null}
      </div>
    </div>
  )

  return (
    <Switch>
      <Route path={companyPaths} exact render={() => navWrapper(aboutSubLinks)} />
      <Route path={careersPaths} render={() => navWrapper(aboutSubLinks, true)} />
      <Route path={sustainabilityPaths} render={() => navWrapper(aboutSubLinks)} />
      <Route path={affiliatesPaths} exact render={() => navWrapper(affiliatesSubLinks)} />
      <Route render={() => navWrapper()} />
    </Switch>
  )
}

Navigation.propTypes = {
  minScroll: PropTypes.number
}

export default Navigation
