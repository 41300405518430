import React from 'react'
import PropTypes from 'prop-types'
import { PageContext, SectionContext } from './contexts'
import { useAnchorClickTracking, useButtonClickTracking } from './hooks'

Page.propTypes = {
  trackingId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export function Page({ trackingId, children }) {
  return <PageContext.Provider value={trackingId}>{children}</PageContext.Provider>
}

Section.propTypes = {
  trackingId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export function Section({ trackingId, children }) {
  return <SectionContext.Provider value={trackingId}>{children}</SectionContext.Provider>
}

Anchor.propTypes = {
  trackingId: PropTypes.string,
  trackingLabel: PropTypes.string,
  onClick: PropTypes.func
  // ...plus anything else you can pass to a native <a>
}

export function Anchor({ trackingId, trackingLabel, onClick, onKeyPress, href, ...props }) {
  const trackAnchorClick = useAnchorClickTracking(trackingId)

  return (
    /* eslint-disable jsx-a11y/anchor-has-content */
    <a
      onClick={(event) => {
        if (trackingId) trackAnchorClick(trackingId, trackingLabel)
        if (typeof onClick === 'function') onClick(event)
      }}
      onKeyPress={(event) => {
        if (trackingId) trackAnchorClick(trackingId, trackingLabel)
        if (typeof onKeyPress === 'function') onKeyPress(event)
      }}
      href={href}
      {...props}
    />
    /* eslint-enable jsx-a11y/anchor-has-content */
  )
}

Button.propTypes = {
  trackingId: PropTypes.string,
  trackingLabel: PropTypes.string,
  onClick: PropTypes.func
  // ...plus anything else you can pass to a native <button>
}

export function Button({ trackingId, trackingLabel, onClick, ...props }) {
  const trackButtonClick = useButtonClickTracking(trackingId, trackingLabel)

  return (
    <button
      onClick={(event) => {
        if (trackingId) trackButtonClick(trackingId, trackingLabel)
        if (typeof onClick === 'function') onClick(event)
      }}
      {...props}
    />
  )
}
