/**
 * Attention! CookieBot is involved here!
 *
 * If you change the experimentId, ensure you declare
 * and classify experimentLocalStorageKey in CookieBot.
 */

export const experimentId = null
export const experimentLocalStorageKey = `experiment_${experimentId}` // ⚠️ This must be self-declared in Cookiebot before deployment

/*
 * 01      / 23         [/ 45] ...etc
 * AA      / BB         [/ CC]
 * Control / Experiment [/ Experiment]
 */
export const variants = []

/**
 * Set an activeVariant in <App /> after mounting:

import getVariant from './experiment/get_variant'
import { reportExperimentEnrolment } from '@envato/google-analytics'

useEffect(() => {
  const activeVariant = getVariant()
  const experiment = {
    id: experimentId,
    variants,
    activeVariant
  }

  reportExperimentEnrolment(experiment)
  setExperiment(experiment)
}, [])

 * Consume the activeVariant:

import VariantSwitcher from './components/variant_switcher/variant_switcher'

<VariantSwitcher
  renderers={{
    control: () => <HeroBanner />,
    experiment: () => <HeroBannerRedesign />
  }}
  loadingWrapper={renderVariant => (
    <div style={{ height: '100vw', background: '#000' }}>
      <div style={{ display: 'none' }}>
        renderVariant()
      </div>
    </div>
  )}
/>

 */
