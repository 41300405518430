import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function AnalyticsPageviewTracker({ title }) {
  const { pathname } = useLocation()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window.ga === 'function') {
      window.ga('set', 'title', title)
      window.ga('set', 'page', pathname)
      window.ga('send', 'pageview')
    }
  }, [pathname, title, loaded])

  window.addEventListener('ga-ready', () => setLoaded(true))

  return null
}

AnalyticsPageviewTracker.propTypes = {
  title: PropTypes.string.isRequired
}
