import React from 'react'
import PropTypes from 'prop-types'
import Hyperlink from '../hyperlink/hyperlink'
import { frontdoorPaths } from '../../routing/paths'
import ufo from './ufo.svg'
import inter from '@envato/ui/src/fonts/Inter-roman.subset.woff2'

SomethingWentWrong.propTypes = {
  type: PropTypes.oneOf(['error', 'timeout', 'notFound']).isRequired
}

const content = {
  error: {
    heading: 'Something went wrong.',
    paragraph: (
      <>
        Sorry about this inconvenience. Try reloading the page or{' '}
        <Hyperlink href={frontdoorPaths.contact}>get in touch</Hyperlink> with us if you need help.
      </>
    )
  },
  notFound: {
    heading: '404 Not Found.',
    paragraph: (
      <>
        There's nothing here! <Hyperlink href={frontdoorPaths.aboutCompany}>Go home</Hyperlink> or{' '}
        <Hyperlink href={frontdoorPaths.contact}>get in touch</Hyperlink> with us if you need help.
      </>
    )
  }
}

export default function SomethingWentWrong({ type }) {
  return (
    <>
      <style>{`
       @font-face {
         font-family: 'Inter';
         font-weight: 100 900;
         font-display: swap;
         font-style: normal;
         font-named-instance: 'Regular';
         src: url('https://www.envato.com${inter}') format('woff2');
       }

      .root {
        font-family: Inter;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 10px;
        text-align: center;
      }

      /* Account for Header component's height */
      @media (max-width: 799px) {
        .root {
          height: calc(100vh - 60px);
        }
      }

      @media (min-width: 800px) {
        .root {
          height: calc(100vh - 80px);
        }
      }

      .image {
        min-height: 0;
        display: block;
        object-fit: contain;
        margin-bottom: 40px;
      }

      .heading {
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 30px;
      }

      .paragraph {
        max-width: 36em;
      }

      .paragraph > a {
        color: #1fb4da;
        text-decoration: none;
      }

      @media (max-height: 400px) {
        .image {
          margin-bottom: 20px;
        }

        .heading {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
      }
    `}</style>
      <div className='root'>
        <img loading='lazy' src={`https://www.envato.com${ufo}`} className='image' alt='' />
        <h1 className='header'>{content[type].heading}</h1>
        <p className='paragraph'>{content[type].paragraph}</p>
      </div>
    </>
  )
}
