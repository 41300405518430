import { useState, useEffect } from 'react'

export default ({ min }) => {
  const [scrollDir, setScrollDir] = useState(undefined)

  useEffect(() => {
    let lastScrollY = window.pageYOffset
    let lastDirection

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset
      const direction = scrollY > lastScrollY ? 'down' : 'up'

      if (lastDirection !== direction) {
        setScrollDir(direction)
        lastDirection = direction
      }

      lastScrollY = scrollY > 0 ? scrollY : 0
    }

    const onScroll = () => {
      const scrollY = window.pageYOffset

      if (scrollY > min) {
        window.requestAnimationFrame(updateScrollDir)
      }
    }

    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [min])

  return scrollDir
}
