import { parse } from 'query-string'
import { experimentLocalStorageKey, variants } from './config'
import { isLocalStorageAvailable } from '../util/local_storage'

const qs = parse(window.location.search, { parseNumbers: true })

const pickRandomVariant = () => Math.floor(Math.random() * variants.length)

const getVariant = () => {
  const defaultVariant = variants.find((variant) => variant.default) || variants[0]

  if (navigator.userAgent === 'ReactSnap') return null

  if (typeof qs.variant !== 'undefined') {
    return variants.find((variant) => variant.id === qs.variant) || defaultVariant
  }

  if (isLocalStorageAvailable()) {
    let variantId = window.localStorage.getItem(experimentLocalStorageKey)

    if (variantId === null) {
      variantId = pickRandomVariant()
      window.localStorage.setItem(experimentLocalStorageKey, variantId)
    } else {
      variantId = parseInt(variantId)
    }

    return variants.find((variant) => variant.id === variantId) || defaultVariant
  }

  return defaultVariant
}

export default getVariant
