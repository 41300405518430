import React from 'react'
import classnames from 'classnames'
import styles from './language_picker.module.css'
import { languages } from '../../i18n/languages'
import Hyperlink from '../hyperlink/hyperlink'
import { frontdoorPaths } from '../../routing/paths'
import I18nContext from '../../contexts/i18n_context'
import usePageId from '../../routing/use_page_id'
import { useLanguage } from '../../i18n'

const LanguagePicker = () => {
  const pageId = usePageId()
  const currentLanguage = useLanguage()
  const href = frontdoorPaths[pageId]

  return (
    <div className={styles.root}>
      <svg className={styles.globe} width='20' height='20' viewBox='0 0 20 20' fill='none' aria-label='Globe'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm5.767
            6.667h-2.142a11.765 11.765 0 00-.983-4.359 5.833 5.833 0 013.125
            4.359zM10 15.833h.375c.758-.491 1.633-2.316 1.783-5H7.842c.116 2.7
            1.025 4.525 1.783 5H10zM7.875 9.167c.15-2.684 1.025-4.509
            1.783-5H10.375c.758.475 1.667 2.3 1.783 5H7.875zm-1.5
            0c.046-1.503.38-2.982.983-4.359a5.833 5.833 0 00-3.125
            4.359h2.142zm-2.142 1.666h2.142c.046 1.503.38 2.982.983 4.359a5.833
            5.833 0 01-3.125-4.359zm9.392 0a11.765 11.765 0 01-.983 4.359 5.833
            5.833 0 003.125-4.359h-2.142z'
          fill='var(--color-grey-400)'
        />
      </svg>
      <div className={styles.languages}>
        {Object.values(languages).map((language) => {
          const isCurrentLanguageActive = language === currentLanguage

          return (
            <I18nContext.Provider value={{ language }} key={language}>
              <Hyperlink
                key={language}
                className={classnames(styles.link, { [styles.active]: isCurrentLanguageActive })}
                href={href}
              >
                {language}
              </Hyperlink>
            </I18nContext.Provider>
          )
        })}
      </div>
    </div>
  )
}

export default LanguagePicker
