import React from 'react'
import FooterNav from './footer_nav/footer_nav'
import SocialList from './social_list/social_list'
import Logo from '../logo/logo'
import styles from './footer.module.css'
import Link from '../link/link'

/* eslint-disable react/jsx-no-target-blank */
// Envato Foundation isn't a malicious third party we need to guard against

export default function Footer({ links }) {
  return (
    <footer className={styles.siteFooter} role='contentinfo'>
      <div className={styles.topRowContainer}>
        <Link href='https://www.envato.com/about' rel='home' aria-label='Envato' className={styles.logo}>
          <Logo whichLogo='envato' />
        </Link>
        <div className={styles.internalLinks}>
          <FooterNav links={links} vertical />
        </div>
        <Link href='https://www.bcorporation.net/en-us/find-a-b-corp/company/envato' className={styles.bcorp}>
          <Logo whichLogo='bcorp' className={styles.bcorpLogo} />
        </Link>
      </div>
      <div className={styles.bottomRowContainer}>
        <FooterNav links={links} />
        <div className={styles.socials}>
          <SocialList />
        </div>
      </div>
      <p className={styles.copyright}>
        © {new Date().getFullYear()} Envato Pty Ltd. Trademarks and brands are the property of their respective owners.
      </p>
    </footer>
  )
}
