// Copied https://reacttraining.com/react-router/web/guides/scroll-restoration
// + added an extra condition around the initial page load

import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

/*
We don't want to scroll to top if this is the first time this component has been rendered
i.e. when we're performing the initial hydration of server-rendered HTML.

If we did, a user would unexpectedly jump back to the top upon JS load if:
- they'd scrolled down the page while JS was loading
- they arrived at a URL with a #fragment-identifier pointing to a location further down the page
*/
let initialHydrationComplete = false

export default function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    if (initialHydrationComplete) {
      window.scrollTo(0, 0)
    } else {
      initialHydrationComplete = true
    }
  }, [pathname])

  return null
}
