/*
 * The function to pop up cookies setting panel when clicking the "Cookie Settings" link event in the footer. This is
 * shared by blog-gatsby and frontdoor.
 */
const cookieSettingsEffect = () => {
  const cookieSettingsLink = document.getElementById('cookieSettings')
  const handleClick = (event) => {
    event.preventDefault()
    if (window.Cookiebot) {
      window.Cookiebot.renew()
    }
  }

  if (cookieSettingsLink) {
    cookieSettingsLink.addEventListener('click', handleClick)
  }

  return () => {
    if (cookieSettingsLink) {
      cookieSettingsLink.removeEventListener('click', handleClick)
    }
  }
}

export default cookieSettingsEffect
