import React, { useContext } from 'react'
import context from '../context'

const Link = (props) => {
  const { LinkComponent = ({ children, ...props }) => <a {...props}>{children}</a> } = useContext(context)

  return <LinkComponent {...props} />
}

export default Link
